import React, { useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { FaKey } from "react-icons/fa";

import animations from "../../lib/animations.lib";
import { respH } from "../../lib/helpers";
import Layout from "../../components/layout";
import NewsLetter from "../../components/news-letter/news-letter.component";
import TeamSection from "../../components/team-section/team-section.component";
import Seo from "../../components/seo";

import "./index.styles.scss";

const WhoAreWePage = () => {
  useEffect(() => {
    const animate = animations();
    animate.animateFadeIn();
  });

  return (
    <Layout pageClass="chi-siamo">
      <Seo
        title="Chi siamo"
        description="La Fucina dei Talenti è una società di formazione e di consulenza nell'ambito dello sviluppo professionale e aziendale."
      />
      <section className="hero header is-large">
        <div className="hero-body">
          <div className="container is-fluid has-text-centered is-italic">
            <h1 className={`title ${respH(2)} has-text-white mb-6`}>
              La migliore preparazione per domani è fare il tuo meglio oggi.
            </h1>
            <h2 className={`subtitle ${respH(3)} has-text-white`}>
              — H. Jackson Brown
            </h2>
          </div>
        </div>
      </section>

      <TeamSection />

      <section className="section is-medium lead">
        <div className="container">
          <div className="columns">
            <div className="column has-text-centered">
              <h1 className={`title ${respH(1)} `}>
                <FaKey /> La nostra missione{" "}
                <span className="is-hidden-touch">
                  è offrire soluzioni per lo sviluppo professionale e aziendale
                </span>
              </h1>
              <h2 className={`title ${respH(2)} is-hidden-desktop`}>
                Offrire soluzioni per lo sviluppo professionale e aziendale
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="columns fade-in">
            <div className="column has-padding-mobile">
              <StaticImage
                className="clipped clipped-one"
                alt="corsi per professionisti image"
                src="../../images/chi-siamo/0.jpg"
              />
            </div>
            <div className="column is-vcenter-desktop has-text-weight-light is-size-4 has-text-centered">
              <div>
                <p className="block">
                  La Fucina dei Talenti è una società di formazione e di
                  consulenza nell'ambito dello sviluppo professionale e
                  aziendale.
                </p>
                <p className="block">
                  Un'offerta formativa che si basa sull'alta professionalità dei
                  suoi docenti e sulle più avanzate soluzioni informatiche del
                  settore.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="columns fade-in has-reversed-columns">
            <div className="column is-vcenter-desktop has-text-weight-light is-size-4 has-text-centered">
              <div>
                <p className="block">
                  La missione è far sviluppare e moltiplicare i talenti di
                  ciascuno tramite la formazione, le più innovative tecniche di
                  gestione della conoscenza.
                </p>
                <p className="block">
                  I nostri valori: professionalità, etica, rispetto per
                  l'ambiente, centralità dell'uomo e del suo sviluppo.
                </p>
              </div>
            </div>
            <div className="column has-padding-mobile">
              <StaticImage
                className="clipped clipped-two"
                quality="80"
                alt="corsi per professionisti image"
                src="../../images/chi-siamo/1.jpg"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="columns is-multiline fade-in">
            <div className="column has-padding-mobile">
              <StaticImage
                className="clipped clipped-three"
                alt="corsi per professionisti image"
                src="../../images/chi-siamo/2.jpg"
              />
            </div>
            <div className="column is-vcenter-desktop is-size-4 has-text-weight-light has-text-centered">
              <p>
                L'obiettivo è lo sviluppo delle capacità e dei talenti
                professionali, individuali e aziendali.
              </p>
            </div>
            <div className="column mt-6 is-full is-size-4 has-text-weight-light has-text-centered">
              <h3 className="title is-size-4-mobile is-size-3-tablet is-size-2-desktop mb-5">
                I nostri servizi:
              </h3>
              <ul>
                <li className="mb-3">
                  Formazione per professionisti e aziende
                </li>
                <li className="mb-3">
                  Consulenza in ambito HR, organizzazione aziendale
                </li>
                <li>Creazione di piattaforme personalizzate e-learning</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <NewsLetter />
    </Layout>
  );
};

export default WhoAreWePage;
