import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import "./team-section.styles.scss";

const Team = () => {
  return (
    <section className="section team is-medium pb-0">
      <div className="container is-max-desktop">
        <div className="columns is-multiline is-centered is-mobile">
          {/* <div className="column is-full has-text-centered mb-4"> */}
          {/*   <h1 className={`title ${respH(1)} mb-6`}> Il nostro team</h1> */}
          {/* </div> */}
          <div className="column is-narrow-mobile">
            <figure className="image is-128x128">
              <StaticImage src="../../images/chi-siamo/team/1.png" alt="" />
            </figure>
          </div>
          <div className="column is-narrow-mobile">
            <figure className="image is-128x128">
              <StaticImage src="../../images/chi-siamo/team/3.png" alt="" />
            </figure>
          </div>
          <div className="column is-narrow-mobile">
            <figure className="image is-128x128">
              <StaticImage src="../../images/chi-siamo/team/2.png" alt="" />
            </figure>
          </div>
          <div className="column is-narrow-mobile">
            <figure className="image is-128x128">
              <StaticImage src="../../images/chi-siamo/team/4.png" alt="" />
            </figure>
          </div>
          <div className="column is-narrow-mobile">
            <figure className="image is-128x128">
              <StaticImage src="../../images/chi-siamo/team/5.png" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
